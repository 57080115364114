/* marginLeft: {
    marginLeft: 10,
  }, */
  .menuText {
    display: "flex" !important;
    align-items: "center";
    text-decoration: "none" !important;
    
  }

.techineformtext{
  margin-bottom: 20px;
}

.form-main{
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  margin: 0 !important;
  
}
.form-textfield{
  margin: 0px 0px 20px 35px !important;
  width: 80% !important;
}
.form-textfield-sm{
  margin: 0px 0px 20px 35px;
  width: 34%;
}
.form-text{
  width: 27%; 
    text-align:right !important;
    justify-content:end;
    margin-top: 14px;
    font-size: 15px;
    margin-right: 15px !important;
    color: rgb(25, 118, 210);
}
.filter-icon{
  /* position: relative;
  top:-55px;
  right:-190px; */
  padding: 10px;
  display: flex ;
  justify-content: center;
  position: fixed !important;
  /* left: 65%; */
  /* bottom: 46%; */
  z-index: 22;
  right: 1%;
  top: 76%;
}
.redirectLink:hover{
  color: rgb(8, 109, 241);
  text-decoration: underline;
}
.redirectLink{
  cursor: pointer;
  text-decoration: none;
  color: #1976d2;
}
.btnLink:hover{
text-decoration: "none";
background-color:"#7b1fa2" !important  ;
color:"white"
}
.css-113bh6a-MuiButtonBase-root-MuiButton-root:hover{
  background-color:"#7b1fa2" ;
}
.filterServ{

}
.form-textfield1{
  margin: 0px 0px 20px 25px !important;
  width: 80%;
}
.form-text1{
  width: 50%;
    text-align:right;
    justify-content:end;
    font-size: 15px;
    margin-right: 15px;
}

.form-text2{
  width: 20%;
    text-align:right;
    justify-content:end;
    /* margin-top: 14px; */
    font-size: 18px;
    margin-right: 15px !important;
}

.form-textfield2{
  /* margin: 0px 0px 14px 0px; */
  width: 21.5%;
}
.zoom{
  font-size: 100%;
    width: 15%;
    text-align: center;
    margin: 10px;
    padding: 10px;
   
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  background-color: rgb(224 230 237 / 88%) !important;
}
.rating_btn{
  cursor: pointer;
    /* text-decoration: none; */
    color: #12202e;
    font-weight: 600;
    margin-left: 25px;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
}
.rating_btn:hover{

    color: #1476d8e8;
    font-weight: 600;
    /* margin-left: 25px;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px; */
}
.form-text3{
  width: 45%;
  font-size: 16px !important;
    margin-right: 12px !important;
}
.form-textfield3{
  /* margin: 0px 0px 14px 0px; */
  width: 12.1%;
}

.css-cveggr-MuiListItemIcon-root{
  color: "green" !important;
}
.MuiDialogActions-root{
  margin-top: -60px;
}

.clsure-datefield{
  /* margin: 0px 0px 14px 0px; */
  width: 17.1%;
}

.fule-textfield{
  /* margin: 0px 0px 14px 0px; */
  width: 18.8%;
}

.rating-inputs{
  width: 27%;
    text-align:right;
    justify-content:end;
    /* margin-top: 14px; */
    font-size: 18px;
    margin-right: 15px !important;
}

.rating-text{
  width: 27%;
  text-align:right;
  justify-content:end;
  margin-bottom: 14px;
  font-size: 18px;
  margin-right: 6px !important;
}

.escalations {
  width: 1px !important;
  height: 100% !important;             
}

.addText {
  left: 0 !important;
  visibility: hidden;
  position: absolute;
  width:0;
  white-space:nowrap; 
  direction:rtl; 
  transition: .1s;
  transform: translateX(0px); 
  pointer-events:none; 
  background-color: red !important;
  color: white;
}

.escalationOrder:hover .addText {
  visibility: visible;
  opacity: .7;
  transform: translateX(0);
  width:60px;
}

.form-text4{
  width: 30%;
    text-align:right;
    justify-content:end;
    font-size: 18px;
    margin-right: 15px !important;
}
